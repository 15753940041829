/* eslint-disable react/jsx-sort-props */
import Head from 'next/head'
import parse from 'html-react-parser'
import { organizationSchema, productSchema, webSiteSchema } from './schema'
import { normalizeString } from '../../shared/helpers/normalizeString'

export interface ProductProps {
  availability?: string
  brand?: string
  description?: string
  images?: Array<string>
  name?: string
  price: number
  sku?: string
}

interface HeaderProps {
  canonicalPath?: string
  categories?: Array<string>
  contentDescription?: string
  metaRobot?: string
  pageImg?: string
  pageNumber?: string
  productInfo?: ProductProps
  titleName?: string
  type?: 'HOME' | 'PDP' | 'STATIC'
}

export const MetaHead = ({
  canonicalPath = '',
  contentDescription = '',
  metaRobot = 'index,follow',
  titleName = '',
  type
}: HeaderProps) => {
  const siteName = 'La Giftcard de Chile'
  const siteHostname = 'https://www.lagiftcarddechile.cl'
  const customTitle = titleName ? parse(`${titleName} | ${siteName}`) : siteName
  const contentTitle = titleName ? `${titleName} | ${siteName}` : siteName
  const canonicalUrl = `${siteHostname}${normalizeString(canonicalPath)}`
  const metaRobotTag =
    canonicalUrl.includes('login') || canonicalUrl.includes('gclid')
      ? 'noindex,follow'
      : metaRobot
  return (
    <Head>
      <title>{customTitle}</title>

      <meta
        name='description'
        content={contentDescription}
      />

      {/* Sitewide default value for all pages */}
      <meta
        property='og:type'
        content='website'
      />
      <meta
        property='og:site_name'
        content='lagiftcarddechile.cl'
      />

      {/* default value for all pages */}
      <meta
        name='robots'
        content={metaRobotTag}
      />

      {/* Same title as <Title /> */}
      <meta
        property='og:title'
        content={contentTitle}
      />

      {/* Same value as Canonical or actual url  */}
      <meta
        property='og:url'
        content={canonicalUrl}
      />

      {/* Same value as Meta description*/}
      <meta
        property='og:description'
        content={contentDescription}
      />

      {/*
        default img given by marketing 1200 px X 627px
      */}
      <meta
        property='og:image'
        content='defaultUrl'
      />
      {/* Viewport default value */}
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1'
      />
      <link
        href='/favicon.ico'
        rel='icon'
      />

      {type === 'HOME' && (
        <>
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: webSiteSchema }}
          />
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: organizationSchema }}
          />
        </>
      )}
      {type === 'PDP' && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: productSchema(canonicalUrl)
          }}
        />
      )}
    </Head>
  )
}
