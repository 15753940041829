const webSiteSchema = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'La Giftcard de Chile',
  url: 'https://www.lagiftcarddechile.cl'
})

const organizationSchema = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'Corporation',
  name: 'La Giftcard de Chile',
  alternateName: 'La Giftcard de Chile',
  url: 'https://www.lagiftcarddechile.cl'
})

const productSchema = (canonicalUrl) => {
  return JSON.stringify({
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: 'giftcard',
    description: 'Compra tu giftcard',
    offers: {
      '@type': 'Offer',
      url: canonicalUrl,
      priceCurrency: 'CLP',
      availability: 'https://schema.org/inStock'
    }
  })
}
export { webSiteSchema, organizationSchema, productSchema }
