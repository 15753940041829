import { useEffect, useMemo, useState } from 'react'
import { cleanLocalStorage } from 'shared/helpers'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import {
  Button,
  Column,
  Container,
  HeaderGiftCard,
  MobileModalWrapper,
  PopUpWrapper,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { AlignItems } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'
import { Context as ResponsiveContext } from '@smu-chile/pkg-unimarc-components/reactResponsive'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile, postBalance } from '@smu-chile/pkg-unimarc-hooks'
import { CheckBalanceForm } from 'components/CheckBalanceForm'
import { QuotationContent } from 'components/Home/QuotationContent'
import { ErrorToast } from 'components/ErrorToast'
import style from './headerGiftcard.module.css'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export interface HeaderGiftcardProps {
  children?: React.ReactNode
  isMobile?: boolean
  alignItems?: AlignItems
  customWidth?: string
  hideButtons?: boolean
}

export const HeaderGiftcard = ({
  children,
  isMobile: isDevice,
  alignItems,
  customWidth,
  hideButtons
}: HeaderGiftcardProps): React.ReactElement => {
  const router = useRouter()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [isShow, setIsShow] = useState(false)
  const [enabledPopUp, setEnabledPopUp] = useState(false)
  const [isEnableButton, setIsEnableButton] = useState(false)
  const [showQuotationModal, setShowQuotationModal] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [toastMessage, setToastMesage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormvalues] = useState({
    giftCardNumber: '',
    code: ''
  })

  const { innerWidth } = useMobile()
  const isMobile = isDevice || innerWidth < 1280
  const withProvider: number = useMemo(() => {
    const hasInnerWith: number = isMobile && !innerWidth ? 500 : 1400
    return innerWidth ? innerWidth : hasInnerWith
  }, [innerWidth, isMobile])

  const popUpWrapperId = getBemId('popUpWrapper', 'container')

  const isSafari =
    typeof window !== 'undefined'
      ? navigator.userAgent.indexOf('Safari') > -1 &&
        navigator.userAgent.indexOf('Chrome') <= -1
      : false
  const classNameByBrowser = isSafari
    ? 'modal__container--mobile-safari'
    : 'modal__container--mobile'

  const onChange = ({ form, errors }) => {
    if (
      form.giftCardNumber &&
      form.code &&
      !errors.giftCardNumber.error &&
      !errors.code.error
    ) {
      setIsEnableButton(true)
      setFormvalues(form)
    } else {
      setIsEnableButton(false)
    }
  }

  const openModal = () => {
    if (isMobile) {
      setIsShow(true)
    } else {
      setEnabledPopUp(!enabledPopUp)
    }
  }

  const closeModal = () => {
    setIsShow(false)
  }

  const applyAndRedirect = async () => {
    if (isEnableButton) {
      const token = await executeRecaptcha('identity_lookup')
      setIsLoading(true)
      postBalance({
        requestOptions: {
          headers: {
            'g-recaptcha-response': token
          }
        },
        data: formValues
      }).then((res) => {
        if (res?.serviceStatus == 200) {
          setIsShow(false)
          setEnabledPopUp(false)
          setIsEnableButton(false)
          localStorage.setItem('balanceData', JSON.stringify(res))
          router.push('/balance')
        } else if (res?.serviceStatus == 400) {
          setToastMesage(
            'Pin o número de Giftcard no válido. Por favor, intenta de nuevo'
          )
          setFetchError(true)
        } else {
          setToastMesage('Ha ocurrido un problema al consultar el saldo.')
          setFetchError(true)
        }
        setIsLoading(false)
      })
    }
  }

  const removeGiftcardData = () => {
    cleanLocalStorage()
    router.push('/')
  }

  const handleShowQuotationModal = () => {
    setShowQuotationModal(true)
  }

  const handleCloseModal = (e) => {
    const element = document.getElementById(popUpWrapperId)
    if (element && element.contains(e.target)) return
    setEnabledPopUp(false)
  }

  useEffect(() => {
    if (enabledPopUp) return
    setIsEnableButton(false)
    setFormvalues({
      giftCardNumber: '',
      code: ''
    })
  }, [enabledPopUp])

  return (
    <ResponsiveContext.Provider value={{ width: withProvider }}>
      <Container
        id='header__giftcard'
        isWrap
        position='relative'
        tagName='header'
        zIndex={getGlobalStyle('--z-index-10')}
      >
        {children}
        <Container
          background={'#FFFFFF'}
          className={`${style['main-header']} ${style['show']}`}
          id='primary'
          justifyContent='center'
          tagName='header'
          zIndex={getGlobalStyle('--z-index-10')}
        >
          <HeaderGiftCard
            alignItems={alignItems}
            customWidth={customWidth}
            handleShowQuotationModal={handleShowQuotationModal}
            hasGiftcardData={false}
            hideButtons={hideButtons}
            logoUrl='/'
            onClickLogout={removeGiftcardData}
            yourBalanceOnClick={openModal}
          />
          {enabledPopUp && (
            <Container
              className={style.backgroundOverlay}
              id={getBemId('popUpWrapper', 'headerContainer')}
              onClick={handleCloseModal}
            >
              <PopUpWrapper
                id={popUpWrapperId}
                rewriteContainerClass={style.popUpContainer}
                rewriteSpanClass={style.popUpCorner}
                width='350px'
              >
                <Container padding='24px'>
                  <Column>
                    <CheckBalanceForm onChange={onChange} />
                    <Row margin='30px 0 0 0'>
                      <Button
                        background={
                          isEnableButton
                            ? getGlobalStyle('--color-background-razzmatazz')
                            : getGlobalStyle('--color-primary-disabled')
                        }
                        borderRadius={getGlobalStyle('--border-radius-xl')}
                        customClassName={classNames({
                          ['button__form-pink']: isEnableButton,
                          [style.buttonCursor]: !isEnableButton
                        })}
                        fontSize={getGlobalStyle('--font-size-xl')}
                        fontWeight='500'
                        height='45px'
                        isLoading={isLoading}
                        label={'Consultar mi saldo'}
                        onClick={applyAndRedirect}
                        width='100%'
                      />
                    </Row>
                  </Column>
                </Container>
              </PopUpWrapper>
            </Container>
          )}
        </Container>
      </Container>
      {isMobile && isShow && (
        <MobileModalWrapper
          blockId='balance'
          body={
            <Container padding='1rem 0 0 0'>
              <CheckBalanceForm onChange={onChange} />
            </Container>
          }
          buttonBackground={
            isEnableButton
              ? getGlobalStyle('--color-background-razzmatazz')
              : undefined
          }
          buttonLabel='Consultar mi saldo'
          dragProps={{
            draggButton: false
          }}
          hasCenterTitle
          headerPadding='30px 0'
          headerTitle={'Consulta tu saldo'}
          iconSize={16}
          isEnableButton={isEnableButton}
          modalConfigsProps={{
            customContainerClassName: classNames({
              [style[classNameByBrowser]]: isMobile
            }),
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            openAnimation: true,
            stickyBottom: true,
            toggleOutside: () => {
              setIsShow(false)
            }
          }}
          onApply={applyAndRedirect}
          onClose={closeModal}
        />
      )}
      <QuotationContent
        isMobile={isMobile}
        setShowModal={setShowQuotationModal}
        showModal={showQuotationModal}
      />
      {fetchError && (
        <ErrorToast
          isMobile={isMobile}
          message={toastMessage}
          onClick={() => {
            setFetchError(false)
            applyAndRedirect()
          }}
          positionContainer='fixed'
          setFetchError={setFetchError}
          top='64px'
        />
      )}
    </ResponsiveContext.Provider>
  )
}
